var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isLoading)?_c('div',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1):_vm._e(),(_vm.ticket && _vm.showDetailTicketIncident)?_c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,3681283568)}),_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.isLoading,"rounded":"lg","outlined":""}},[_c('v-card-title',{staticClass:"primary--text px-8"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('h1',{staticClass:"text-h6"},[_vm._v(" Detalle del Ticket #"+_vm._s(_vm.ticket?.num_ticket)+" ")])]),(_vm.confirmedTicket)?_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"d-md-flex"},[(_vm.ticket?.status === 'RESUELTO')?_c('close-ticket-dialog',{staticClass:"mr-1",attrs:{"listenCloseTicket":_vm.listenCloseTicket,"ticketId":_vm.ticket?.id}}):_vm._e(),(
										['CERRADO', 'CALIFICADO'].includes(
											_vm.ticket?.status
										)
									)?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":() => (_vm.isRatingActive = true)}},[_vm._v(" "+_vm._s(_vm.ticket?.status === 'CALIFICADO' ? 'Ver calificación' : 'Calificar')+" ")]):_vm._e()],1)]):_vm._e()],1)],1),_c('v-card-text',{staticClass:"pa-8"},[(_vm.ticket)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 6 : 12}},[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Fecha de Registro:","icon":"mdi-calendar-range"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.ticket?.created_at))+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Registrado por:","icon":"mdi-account-outline"}},[_vm._v(" "+_vm._s(_vm.ticket?.assigned_user_id?.fullName)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Usuarios adicionales:","icon":"mdi-account-outline"}},[(
												_vm.ticket?.ticket_additional_users.length
											)?_c('v-btn',{staticClass:"ml-1",staticStyle:{"width":"150px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.additionalUsers()}}},[_vm._v("Ver detalle "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-eye")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Usuarios adicionales:","icon":"mdi-account-outline"}},[(
												_vm.ticket?.ticket_additional_users.length
											)?_c('v-btn',{staticClass:"ml-1",staticStyle:{"width":"150px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.additionalUsers()}}},[_vm._v("Ver detalle "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-eye")])],1):_vm._e()],1)],1),(_vm.ticket.status === 'PENDIENTE AP')?_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Usuario aprobador:","icon":"mdi-account-outline"}},[_vm._v(" "+_vm._s(_vm.ticketUserConfirm.user_confirm.fullName)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Área:","icon":"mdi-lan"}},[_vm._v(" "+_vm._s(_vm.printSubtype(_vm.ticket))+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Activos:","icon":"mdi-monitor-cellphone"}},[_vm._v(" "+_vm._s(_vm.printAssets(_vm.ticket?.assets))+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Descripción:","icon":"mdi-text"}},[_vm._v(" "+_vm._s(_vm.ticket?.description)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Tipo de atención:","icon":"mdi-headset"}},[_vm._v(" "+_vm._s(_vm.ticket?.attention_type)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Evidencia:","icon":"mdi-file-multiple-outline"}},[(
												Array.isArray(_vm.ticket?.evidences) &&
												_vm.ticket.evidences.length > 0
											)?_c('div',_vm._l((_vm.ticket?.evidences),function(evidence,indexFile){return _c('v-btn',{key:indexFile + '-indexFile',attrs:{"href":_vm.signedEvidences[evidence.id] ||
													evidence.evidence_file,"color":"primary","small":"","outlined":"","dark":"","loading":_vm.loadingEvidences[evidence.id]},on:{"click":function($event){$event.preventDefault();return _vm.getEvidenceSigned(
														evidence.id,
														evidence.evidence_file
													)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-link")]),_vm._v(" Archivo ")],1)}),1):_c('div',[_vm._v("-")])])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Fecha de Atención:","icon":"mdi-calendar-range"}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.ticket?.created_at))+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Asignado a:","icon":"mdi-account-outline"}},[_vm._v(" "+_vm._s(_vm.ticket?.responsible_id?.fullName ? _vm.ticket?.responsible_id?.fullName : '-')+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Atendido por:","icon":"mdi-account-outline"}},[_vm._v(" "+_vm._s(_vm.ticket?.ticket_attention?.responsible_id ?.fullName ? _vm.ticket?.ticket_attention ?.responsible_id?.fullName : '-')+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Creado por:","icon":"mdi-account-outline"}},[_vm._v(" "+_vm._s(_vm.ticket?.creator_id?.fullName)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 12 : 6}},[_c('ticket-detail-element',{attrs:{"label":"Estado:","icon":"mdi-list-status"}},[(_vm.getIncidentStatus(_vm.ticket?.status))?_c('v-chip',{attrs:{"small":"","dark":"","color":_vm.getIncidentStatus(_vm.ticket?.status)
													?.color}},[_vm._v(" "+_vm._s(_vm.ticket?.status)+" ")]):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":_vm.isColumns ? 6 : 12}},[(
									_vm.ticket?.ticket_attention &&
									!['SOLICITADO', 'CANCELADO'].includes(
										_vm.ticket?.status
									)
								)?_c('div',[(
										![
											'SOLICITADO',
											'CANCELADO',
											'PENDIENTE AP',
										].includes(_vm.ticket?.status)
									)?_c('ticket-comments',{attrs:{"userType":"USUARIO","status":_vm.ticket?.status,"ticket_attention":_vm.ticket?.ticket_attention?.id,"request":_vm.ticket,"showSendComment":true},on:{"refresh":_vm.getTicketId}}):_vm._e()],1):_vm._e()])],1):_vm._e()],1)],1)],1),_c('rating-dialog',{attrs:{"ticketId":_vm.ticket?.id},on:{"refresh":_vm.getTicketId},model:{value:(_vm.isRatingActive),callback:function ($$v) {_vm.isRatingActive=$$v},expression:"isRatingActive"}}),_c('user-additional-dialog',{attrs:{"item":_vm.ticket},model:{value:(_vm.additionalUsersDialog),callback:function ($$v) {_vm.additionalUsersDialog=$$v},expression:"additionalUsersDialog"}})],1):(_vm.ticket && !_vm.showDetailTicketIncident)?_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"error","outlined":"","pill":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" Usted no tiene permisos para ver este ticket. ")],1)],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }