var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showMessages)?_c('div',{attrs:{"id":"TicketComments"}},[_c('v-row',{staticClass:"px-6",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('div',[_vm._v("Comentarios:")])])],1),(_vm.request?.subtype?.flag_comment && _vm.ticketComments.length)?_c('div',{staticClass:"wrapper-chat",attrs:{"id":"wrapper-chat"}},_vm._l((_vm.ticketComments),function(item,index){return _c('div',{key:index + '-comment',class:['comment ', _vm.getPositionComment(item)]},[_c('v-alert',{staticClass:"px-10",style:({ width: '100%', backgroundColor: '#fafafa' }),attrs:{"border":"left","elevation":"0","colored-border":"","color":"primary--text"}},[_c('div',{staticClass:"mb-1"},[_c('v-icon',{attrs:{"color":"primary","small":"","left":""}},[_vm._v(" mdi-message-outline ")]),_c('span',{staticClass:"text-body-2 primary--text",domProps:{"textContent":_vm._s(
							item?.flag_system_ticket
								? 'INCIDENTES APP'
								: item?.creator_id?.fullName
						)}}),_c('span',{staticClass:"text-caption ml-2 text--secondary",domProps:{"textContent":_vm._s(_vm.formatDate(item?.created_at))}})],1),_c('div',{staticClass:"pl-6 text-caption"},[_c('div',{staticClass:"comment__detail--text",domProps:{"textContent":_vm._s(item?.content)}}),_c('div',{staticClass:"comment__detail--text"},_vm._l((item?.files || []),function(file,ixFile){return _c('v-btn',{key:ixFile + '-ixFile',attrs:{"color":"primary","href":"#","target":"_blank","small":"","text":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.openFile(file)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-link ")]),_vm._v(" "+_vm._s(_vm.getNamefile(file?.comment_file))+" ")],1)}),1)])])],1)}),0):_vm._e(),_c('div',{staticClass:"pa-6"},[(
				['RESUELTO', 'CONSULTA','EN ATENCION'].includes(_vm.status))?_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[(_vm.userType == 'RESPONSIBLE' && _vm.request?.subtype?.flag_comment && _vm.showSendComment)?_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"auto"}},[_c('v-select',{attrs:{"items":_vm.types,"dense":"","outlined":"","hide-details":""},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1):_vm._e(),(
				['RESUELTO', 'CONSULTA', 'EN ATENCION'].includes(_vm.status) &&
				_vm.request?.subtype?.flag_file
			)?_c('v-col',[_c('import-file-icon',{model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1):_vm._e()],1):_vm._e(),(['RESUELTO', 'CONSULTA','EN ATENCION'].includes(_vm.status) && _vm.showSendComment)?_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"hide-details":"","placeholder":"Escribir comentario","outlined":"","dense":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingSave},on:{"click":_vm.saveTicketComment}},[_vm._v(" Enviar "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-send ")])],1)],1)],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }